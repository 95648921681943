.logoIcon {
  & path {
    fill: var(--color-text);
  }

  &:hover {
    & .path1 {
      fill: #f58a34;
    }
    & .path2 {
      fill: #3a66af;
    }
    & .path3 {
      fill: #00634a;
    }
    & .path4 {
      fill: #b74c31;
    }
  }
}
