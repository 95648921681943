.svg-icons--24x24-arrow-dims {
  width: 24px;
  height: 24px;
}

.svg-icons--24x24-arrow-outside-dims {
  width: 24px;
  height: 24px;
}

.svg-icons--24x24-dropdown-dims {
  width: 24px;
  height: 24px;
}

.svg-icons--24x24-dropdown-up-dims {
  width: 24px;
  height: 24px;
}

.svg-icons--24x24-grid-dims {
  width: 24px;
  height: 24px;
}

.svg-icons--24x24-grid2-dims {
  width: 24px;
  height: 24px;
}

.svg-icons--24x24-list-dims {
  width: 24px;
  height: 24px;
}

.svg-icons--24x24-list2-dims {
  width: 24px;
  height: 24px;
}

.svg-icons--24x24-list3-dims {
  width: 24px;
  height: 24px;
}

.svg-icons--24x24-menu2-dims {
  width: 24px;
  height: 24px;
}

.svg-icons--24x24-minus-dims {
  width: 24px;
  height: 24px;
}

.svg-icons--24x24-plus-dims {
  width: 24px;
  height: 24px;
}

.svg-icons--40x40-arrow-back-dims {
  width: 40px;
  height: 40px;
}

.svg-icons--40x40-arrow-back-1-dims {
  width: 44px;
  height: 44px;
}

.svg-icons--40x40-arrow-back-2-dims {
  width: 42px;
  height: 42px;
}

.svg-icons--40x40-arrow-back-3-dims {
  width: 40px;
  height: 40px;
}

.svg-icons--40x40-arrow-default-dims {
  width: 40px;
  height: 40px;
}

.svg-icons--40x40-arrow-down-dims {
  width: 40px;
  height: 40px;
}

.svg-icons--40x40-arrow-dropdown-dims {
  width: 40px;
  height: 40px;
}

.svg-icons--40x40-arrow-gallery-dims {
  width: 40px;
  height: 40px;
}

.svg-icons--40x40-arrow-hover-dims {
  width: 40px;
  height: 40px;
}

.svg-icons--40x40-close-dims {
  width: 40px;
  height: 40px;
}

.svg-icons--40x40-minus-dims {
  width: 42px;
  height: 40px;
}

.svg-icons--40x40-plus-dims {
  width: 42px;
  height: 42px;
}

.svg-icons--64x64-close-dims {
  width: 64px;
  height: 64px;
}

.svg-icons--arrow-back-dims {
  width: 64px;
  height: 64px;
}

.svg-icons--arrow-default-dims {
  width: 40px;
  height: 40px;
}

.svg-icons--arrow-gallery-white-dims {
  width: 64px;
  height: 64px;
}

.svg-icons--badge-dims {
  width: 64px;
  height: 64px;
}

.svg-icons--close2-dims {
  width: 64px;
  height: 64px;
}

.svg-icons--fb-dims {
  width: 32px;
  height: 32px;
}

.svg-icons--image-placeholder-dims {
  width: 296px;
  height: 296px;
}

.svg-icons--instagram-dims {
  width: 32px;
  height: 32px;
}

.svg-icons--logo-footer-dims {
  width: 880px;
  height: 161px;
}

.svg-icons--map-pin-dims {
  width: 64px;
  height: 80px;
}

.svg-icons--minus-dims {
  width: 64px;
  height: 64px;
}

.svg-icons--plus-dims {
  width: 64px;
  height: 64px;
}

.svg-icons--plus-1-dims {
  width: 64px;
  height: 64px;
}

.svg-icons--plus-2-dims {
  width: 64px;
  height: 64px;
}

.svg-icons--telegram-dims {
  width: 32px;
  height: 32px;
}

.svg-icons--vk-dims {
  width: 32px;
  height: 32px;
}
